import styled from "@emotion/styled";
import theme from "src/theme";

export const SurveyLogo = styled("img")({
  width: "190px",
  height: "190px",
  marginBottom: "35px",

  [theme.breakpoints.down("md")]: {
    width: "114px",
    height: "114px",
  },
});
