import React from "react";
import {
  SupportText,
  SupportUrl,
  SupportWrapper,
} from "src/pages/JoinSession/styles";
import { SUPPORT_EMAIL } from "src/constants/support";

const ContactSupport = () => {
  return (
    <SupportWrapper>
      <SupportText>Having trouble connecting to your session?</SupportText>
      <SupportUrl onClick={() => (window.location.href = SUPPORT_EMAIL)}>
        Contact support
      </SupportUrl>{" "}
    </SupportWrapper>
  );
};

export default ContactSupport;
