import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect } from "react";
import { useGetMemberQuery } from "./api/main";
import { useLDClient } from "launchdarkly-react-client-sdk";
import {
  useGetCompletedWelcomeSessionQueryResolver,
  useGetExpertQueryResolver,
} from "./api/resolvers";

const AppWrapper = ({ children }: any) => {
  const { isAuthenticated } = useAuth0();
  const { data: member } = useGetMemberQuery();
  const { data: expert } = useGetExpertQueryResolver();
  const { data: welcomeSessionCompleted } =
    useGetCompletedWelcomeSessionQueryResolver();

  const ldClient = useLDClient();

  useEffect(() => {
    if (
      !ldClient ||
      !member ||
      !expert ||
      welcomeSessionCompleted === undefined
    ) {
      return;
    }

    const createdDate = new Date(member.created);

    ldClient.identify({
      email: member?.email,
      key: member?.uuid,
      custom: {
        created: createdDate.getTime(),
        expert: expert.email,
        welcome_completed: welcomeSessionCompleted,
      },
    });
  }, [member, ldClient, expert, welcomeSessionCompleted]);

  return <>{children}</>;
};

export default AppWrapper;
